window.$ = $;

window.alert = function(message, type = 'danger') {
    let $toast = $(`
        <div class="toast align-items-center text-white bg-${type} border-0" role="alert" aria-live="assertive" aria-atomic="true">
            <div class="toast-body d-flex" data-bs-dismiss="toast">
                <div class='me-2'>
                    ${message}
                </div>
                <button type="button" class="btn-close btn-close-white ms-auto" data-bs-dismiss="toast" aria-label="Close"></button>
            </div>
        </div>
    `);
    $('.toast-container').prepend($toast);
    $toast.toast('show');
};

// przenosi pojedyncze znaki z końca linijki na początek następnej
export function addNbsps(el) {
    $(el).find(":not(iframe):not(style):not(script)").add(el).contents().filter((i, n) => n.nodeType == 3).each(function () {
        var startWhitespace = /^\s/.test(this.nodeValue) ? ' ' : '';
        var endWhitespace = /\s$/.test(this.nodeValue) ? ' ' : '';
        this.nodeValue = startWhitespace + (' ' + this.nodeValue + ' ').replace(/(\s\w)\s+/g, "$1\xA0").trim() + endWhitespace;
    });
};

addNbsps('body');

// $('[data-toggle="tooltip"]').tooltip();

$.fn.initJs = function () {
    $(window).trigger('init', this);
    return this;
};
Object.defineProperty($, "init", {
    set(fn) {
        $(window).on('init', function(e, el) {
            fn(el, e);
        });
    }
});
window.addEventListener('DOMContentLoaded', () => $('body').initJs());

// s ask

$('.s-ask').each(function(){

    let that = $(this),
        selected = [],
        box = $(this).find('.s-ask__ask-box'),
        navCounter = that.find('.s-ask__nav p span'),
        prevButton = that.find('.s-ask__nav-prev');

    box.click(function(e){

        e.preventDefault();

        let answer = $(this).data('answer'),
            index = $(this).closest('.s-ask__ask-group').index();

        selected[index] = answer;

        let nextGroup = $(this).parent().next('.s-ask__ask-group');

        if(nextGroup.length) {
            $(this).parent().hide();
            nextGroup.fadeIn(200).css('display', 'flex');
            navCounter.html(index + 2);
        } else {

            that.find('[data-result="' + selected.toString() + '"]').fadeIn(200).css('display', 'flex');
            that.find('.s-ask__ask, .s-ask__nav').hide();

        }

    });

    prevButton.click(function(e){

        e.preventDefault();

        let activeGroup = that.find('.s-ask__ask-group'),
            prevGroup = that.find('.s-ask__ask-group:visible').prev(),
            index = prevGroup.index();

        if(prevGroup.length) {

            activeGroup.hide();
            prevGroup.fadeIn(200).css('display', 'flex');
            navCounter.html(index + 1);

        }

    });

});

$('.header-primary__logo a, .ask, .s-ask__product-back a').click(function(e){

    e.preventDefault();

    $('.s-ask__ask-group').hide().eq(0).fadeIn(200);
    $('.s-ask__ask, .s-ask__nav').fadeIn(200);
    $('.s-ask__nav p span').html(1);
    $('.s-ask__product').hide();

    $('html, body').animate({
		scrollTop: $('.s-ask').offset().top
	}, 300);

});