const navigation = document.getElementById('navigation');
const navigationToggler = navigation?.querySelector('.navbar-toggler');
const dropdown = document.getElementById('navigation-dropdown');

dropdown?.querySelectorAll('.nav-item').forEach((item) => {
	if (!item.classList.contains('dropdown')) {
		item.addEventListener('click', () => {
			toggleDropdown();
		});
	}
});

const toggleDropdown = () => {
	if (navigationToggler?.getAttribute('aria-expanded') == 'true') {
		navigationToggler?.click();
	}
};

// scroll to

$('a[href*="#"]').not('[href="#"]').click(function(e) {

	e.preventDefault();

    let href = $(this).attr('href'),
        sectionName = href.split('#')[1],
        section = $('#' + sectionName),
        sectionOffsetTop = section.offset().top;

	$('html, body').animate({
		scrollTop: sectionOffsetTop
	}, 300);

	return false;

});